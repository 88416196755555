import { putGameInfo, getGameInfo } from "./gameCRUD";
import { DataStore } from "aws-amplify";
import { WebsiteBenahmedEducation } from "../models";
import { getLessonId } from "../utility/helper";

export const lessonsExercises = async (lessonId, version) => {
  // todo create lessons exercsies
};

const clearGameInfo = async (date, title) => {
  const newDate = Date.now();
  const intervale = (newDate - date) / (1000 * 60);
  if (intervale > 5) {
    await putGameInfo({
      name: "gameInfo",
      lessonTitle: [title],
      lessonInfo: [],
      date: Date.now(),
    });
    return true;
  }
  return false;
};

const cacheForTitle = async title => {
  const value = await getGameInfo();
  if (!value) {
    await putGameInfo({
      name: "gameInfo",
      lessonTitle: [title],
      lessonInfo: [],
      date: new Date(),
    });
    return false;
  }
  const { lessonTitle, date } = value;
  if (await clearGameInfo(date, title)) return false;
  if (lessonTitle.includes(title)) {
    return true;
  } else {
    await putGameInfo({ ...value, lessonTitle: [...lessonTitle, title] });
    return false;
  }
};

export const getLessonsTitle = async (
  PK,
  values,
  userType,
  myLessonsOnly = true
) => {
  const { classroomId, subject, trimester } = values;
  const lessonId = getLessonId(values);
  let lessons = [];
  if (userType !== "teacher" || !myLessonsOnly) {
    lessons = await DataStore.query(WebsiteBenahmedEducation, w =>
      w.and(w => [w.GSI1_SK.beginsWith(lessonId), w.type.eq("lesson")])
    );
  } else {
    lessons = await DataStore.query(WebsiteBenahmedEducation, w =>
      w.and(w => [w.PK.eq(PK), w.SK.beginsWith(lessonId), w.type.eq("lesson")])
    );
  }

  if (lessons.length > 0) {
    return {
      titles: [
        ["إختر الدرس", 0],
        ...lessons
          .filter(l => l.lessonTrimester === parseInt(trimester))
          .map((l, index) => [l.title, l.order ? l.order : index + 1]),
      ],
      count: lessons.length,
    };
  }

  return { titles: [["لا يوجد دروس", 0]], count: 0 };
};

export const updateLesson = async (id, value) => {
  // todo complete update lesson
};

export const setLesson = async value => {
  const { PK, SK } = value;
  const [lesson] = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  if (lesson) {
    return false;
  }
  return await DataStore.save(new WebsiteBenahmedEducation(value));
};

export const getLesson = async (PK, values) => {
  const { level, division, subject, order } = values;
  if (level && division && subject && order) {
    const [lesson] = await DataStore.query(WebsiteBenahmedEducation, w =>
      w.and(w => [w.PK.eq(PK), w.SK.eq(getLessonId(values))])
    );
    return lesson;
  }
  return null;
};

export const deleteLesson = async id => {
  const lesson = await DataStore.query(WebsiteBenahmedEducation, id);
  if (lesson) {
    return await DataStore.delete(lesson);
  }
};
