import React, { useContext, useEffect, useState } from "react";

import { lessonContext } from "../context/lessonsContext";

const PrintingHeader = ({ page = true, type }) => {
  const {
    lessonInfo: { domain, level, lessonType, unite, division },
  } = useContext(lessonContext);

  return !type || type === lessonType ? (
    <header className={`print ${page ? "page" : ""}`}>
      <div>
        <p>{`المستوى: ${level} ${division}`}</p>
        <p>{`الحصة: ${lessonType}`}</p>
      </div>
      <div>
        <p>{`المجال: ${domain}`}</p>
        <p>{`الوحدة: ${unite}`}</p>
      </div>
    </header>
  ) : null;
};

export default PrintingHeader;
