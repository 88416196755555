/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateStudentUserId = /* GraphQL */ `
  mutation UpdateStudentUserId($password: String, $userId: String) {
    updateStudentUserId(password: $password, userId: $userId) {
      statusCode
      body {
        code
        message
      }
    }
  }
`;
export const updateSubscription = /* GraphQL */ `
  mutation UpdateSubscription($key: String, $version: Int) {
    updateSubscription(key: $key, version: $version) {
      id
      PK
      SK
      type
      GSI1_PK
      GSI1_SK
      GSI2_PK
      GSI2_SK
      GSI3_PK
      GSI3_SK
      firstName
      lastName
      birthday
      phone
      sex
      summoned
      summonDate
      summonCounter
      attendancy
      continuousMonitoring {
        observationRevision
        observationBehavior
        observationNotebook
        option4
      }
      labWork
      tests {
        test1
        test2
      }
      exam
      subject
      title
      teacherId
      studentId
      userType
      phase
      isParent
      level
      observationDate
      content
      solution
      subType
      mark {
        pointType
        amount
      }
      cause
      division
      observations {
        observationDate
        mark {
          pointType
          amount
        }
        cause
        type
        _version
      }
      pointSchema {
        trimester1 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
        trimester2 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
        trimester3 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
      }
      currentTrimester
      lessonTrimester
      order
      tools
      objectifs
      preconditions
      subtitle {
        title
        time
      }
      lessonType
      domain
      unite
      difficulty
      question
      result
      suggestions
      position
      endSub
      IdN
      freeSolution
      silverStars
      goldStars
      imageKey
      tutoring {
        confirmed
        day
        time
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const getExoSolution = /* GraphQL */ `
  mutation GetExoSolution($PK: String!, $SK: String!, $difficulty: Int!) {
    getExoSolution(PK: $PK, SK: $SK, difficulty: $difficulty) {
      id
      PK
      SK
      type
      GSI1_PK
      GSI1_SK
      GSI2_PK
      GSI2_SK
      GSI3_PK
      GSI3_SK
      firstName
      lastName
      birthday
      phone
      sex
      summoned
      summonDate
      summonCounter
      attendancy
      continuousMonitoring {
        observationRevision
        observationBehavior
        observationNotebook
        option4
      }
      labWork
      tests {
        test1
        test2
      }
      exam
      subject
      title
      teacherId
      studentId
      userType
      phase
      isParent
      level
      observationDate
      content
      solution
      subType
      mark {
        pointType
        amount
      }
      cause
      division
      observations {
        observationDate
        mark {
          pointType
          amount
        }
        cause
        type
        _version
      }
      pointSchema {
        trimester1 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
        trimester2 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
        trimester3 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
      }
      currentTrimester
      lessonTrimester
      order
      tools
      objectifs
      preconditions
      subtitle {
        title
        time
      }
      lessonType
      domain
      unite
      difficulty
      question
      result
      suggestions
      position
      endSub
      IdN
      freeSolution
      silverStars
      goldStars
      imageKey
      tutoring {
        confirmed
        day
        time
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createWebsiteBenahmedEducation = /* GraphQL */ `
  mutation CreateWebsiteBenahmedEducation(
    $input: CreateWebsiteBenahmedEducationInput!
    $condition: ModelWebsiteBenahmedEducationConditionInput
  ) {
    createWebsiteBenahmedEducation(input: $input, condition: $condition) {
      id
      PK
      SK
      type
      GSI1_PK
      GSI1_SK
      GSI2_PK
      GSI2_SK
      GSI3_PK
      GSI3_SK
      firstName
      lastName
      birthday
      phone
      sex
      summoned
      summonDate
      summonCounter
      attendancy
      continuousMonitoring {
        observationRevision
        observationBehavior
        observationNotebook
        option4
      }
      labWork
      tests {
        test1
        test2
      }
      exam
      subject
      title
      teacherId
      studentId
      userType
      phase
      isParent
      level
      observationDate
      content
      solution
      subType
      mark {
        pointType
        amount
      }
      cause
      division
      observations {
        observationDate
        mark {
          pointType
          amount
        }
        cause
        type
        _version
      }
      pointSchema {
        trimester1 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
        trimester2 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
        trimester3 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
      }
      currentTrimester
      lessonTrimester
      order
      tools
      objectifs
      preconditions
      subtitle {
        title
        time
      }
      lessonType
      domain
      unite
      difficulty
      question
      result
      suggestions
      position
      endSub
      IdN
      freeSolution
      silverStars
      goldStars
      imageKey
      tutoring {
        confirmed
        day
        time
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateWebsiteBenahmedEducation = /* GraphQL */ `
  mutation UpdateWebsiteBenahmedEducation(
    $input: UpdateWebsiteBenahmedEducationInput!
    $condition: ModelWebsiteBenahmedEducationConditionInput
  ) {
    updateWebsiteBenahmedEducation(input: $input, condition: $condition) {
      id
      PK
      SK
      type
      GSI1_PK
      GSI1_SK
      GSI2_PK
      GSI2_SK
      GSI3_PK
      GSI3_SK
      firstName
      lastName
      birthday
      phone
      sex
      summoned
      summonDate
      summonCounter
      attendancy
      continuousMonitoring {
        observationRevision
        observationBehavior
        observationNotebook
        option4
      }
      labWork
      tests {
        test1
        test2
      }
      exam
      subject
      title
      teacherId
      studentId
      userType
      phase
      isParent
      level
      observationDate
      content
      solution
      subType
      mark {
        pointType
        amount
      }
      cause
      division
      observations {
        observationDate
        mark {
          pointType
          amount
        }
        cause
        type
        _version
      }
      pointSchema {
        trimester1 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
        trimester2 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
        trimester3 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
      }
      currentTrimester
      lessonTrimester
      order
      tools
      objectifs
      preconditions
      subtitle {
        title
        time
      }
      lessonType
      domain
      unite
      difficulty
      question
      result
      suggestions
      position
      endSub
      IdN
      freeSolution
      silverStars
      goldStars
      imageKey
      tutoring {
        confirmed
        day
        time
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteWebsiteBenahmedEducation = /* GraphQL */ `
  mutation DeleteWebsiteBenahmedEducation(
    $input: DeleteWebsiteBenahmedEducationInput!
    $condition: ModelWebsiteBenahmedEducationConditionInput
  ) {
    deleteWebsiteBenahmedEducation(input: $input, condition: $condition) {
      id
      PK
      SK
      type
      GSI1_PK
      GSI1_SK
      GSI2_PK
      GSI2_SK
      GSI3_PK
      GSI3_SK
      firstName
      lastName
      birthday
      phone
      sex
      summoned
      summonDate
      summonCounter
      attendancy
      continuousMonitoring {
        observationRevision
        observationBehavior
        observationNotebook
        option4
      }
      labWork
      tests {
        test1
        test2
      }
      exam
      subject
      title
      teacherId
      studentId
      userType
      phase
      isParent
      level
      observationDate
      content
      solution
      subType
      mark {
        pointType
        amount
      }
      cause
      division
      observations {
        observationDate
        mark {
          pointType
          amount
        }
        cause
        type
        _version
      }
      pointSchema {
        trimester1 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
        trimester2 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
        trimester3 {
          origine
          label
          maxAmount
          minAmount
          startingAmount
        }
      }
      currentTrimester
      lessonTrimester
      order
      tools
      objectifs
      preconditions
      subtitle {
        title
        time
      }
      lessonType
      domain
      unite
      difficulty
      question
      result
      suggestions
      position
      endSub
      IdN
      freeSolution
      silverStars
      goldStars
      imageKey
      tutoring {
        confirmed
        day
        time
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createKeys = /* GraphQL */ `
  mutation CreateKeys(
    $input: CreateKeysInput!
    $condition: ModelKeysConditionInput
  ) {
    createKeys(input: $input, condition: $condition) {
      id
      PK
      SK
      type
      subType
      duration
      isActivated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateKeys = /* GraphQL */ `
  mutation UpdateKeys(
    $input: UpdateKeysInput!
    $condition: ModelKeysConditionInput
  ) {
    updateKeys(input: $input, condition: $condition) {
      id
      PK
      SK
      type
      subType
      duration
      isActivated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteKeys = /* GraphQL */ `
  mutation DeleteKeys(
    $input: DeleteKeysInput!
    $condition: ModelKeysConditionInput
  ) {
    deleteKeys(input: $input, condition: $condition) {
      id
      PK
      SK
      type
      subType
      duration
      isActivated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
