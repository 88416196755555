import React, { createContext, useState } from "react";

const lessonContext = createContext(undefined);
const lessonDispatchContext = createContext(undefined);

function LessonProvider({ children }) {
  const [lessonDetails, setLessonDetails] = useState({
    version: "default",
    versions:['default'],
    exercisesChanged:false,
    lessonInfo:{ }
  });

  return (
    <lessonContext.Provider value={lessonDetails}>
      <lessonDispatchContext.Provider value={setLessonDetails}>
        {children}
      </lessonDispatchContext.Provider>
    </lessonContext.Provider>
  );
}

export { LessonProvider , lessonContext , lessonDispatchContext  };