import React from "react";

export const InitModulesContext = React.createContext();

export const InitModulesProvider = ({ children, breackpoints, browser }) => {
  const bp = breackpoints();
  bp({
    xlarge: ["1281px", "1680px"],
    large: ["981px", "1280px"],
    medium: ["737px", "980px"],
    small: ["481px", "736px"],
    xsmall: ["361px", "480px"],
    xxsmall: [null, "360px"],
    "xlarge-to-max": "(min-width: 1681px)",
    "small-to-xlarge": "(min-width: 481px) and (max-width: 1680px)",
  });
  const initModules = {
    breackpoints: bp,
    browser: browser(),
  };
  return (
    <InitModulesContext.Provider value={initModules}>
      {children}
    </InitModulesContext.Provider>
  );
};
