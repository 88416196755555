import React, { createContext, useState } from "react";

const detailsContext = createContext(undefined);
const detailsDispatchContext = createContext(undefined);

function DetailsProvider({ children }) {
  const [details, setDetails] = useState({ أقسام: [], التلاميذ: [] });
  return (
    <detailsContext.Provider value={details}>
      <detailsDispatchContext.Provider value={setDetails}>
        {children}
      </detailsDispatchContext.Provider>
    </detailsContext.Provider>
  );
}

export { DetailsProvider, detailsContext, detailsDispatchContext };
