export const getScores = async name => {
  return { name: "scores", lastScore: 0, bestScore: 0 };
};

export const setScores = async (value, key = "scores") => {
  // impliment set score
};

export const putGameInfo = async (value, key = "gameInfo") => {
  // impliment set game infos
};

export const getGameInfo = async () => {
  return { name: "gameInfo", lessonTitle: [], lessonInfo: [], date: 0 };
};
