import React from "react";

import PrintingHeader from "./PrintingHeader";
import GoToTuto from "./GoToTuto";
import ArticlesAds from "components/ArticlesAds";
import ListingAds from "components/ListingAds";
import MyVideo from "components/MyVideo/MyVideo";

const MyParagraph = props => <p className="paragraph" {...props} />;
const MyListItem = props => (
  <li className="paragraph" style={{ fontSize: "1em" }} {...props} />
);
const MyOredredList = props => (
  <ol className="paragraph orderedList" {...props} />
);
const MyUnoredredList = props => (
  <ul className="paragraph unorderedList" {...props} />
);
export const components = {
  PrintingHeader,
  p: MyParagraph,
  ol: MyOredredList,
  ul: MyUnoredredList,
  GoToTuto,
  MyVideo,
  ArticlesAds: ListingAds,
};

export const scope = {
  PrintingHeader,
  GoToTuto,
  ArticlesAds,
  ArticlesAds: ListingAds,
};
