// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/App.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-game-js": () => import("./../../../src/pages/game.js" /* webpackChunkName: "component---src-pages-game-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offers-js": () => import("./../../../src/pages/Offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-parse-qrcode-js": () => import("./../../../src/pages/ParseQrcode.js" /* webpackChunkName: "component---src-pages-parse-qrcode-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/Signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/Signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-دروس-خصوصية-js": () => import("./../../../src/pages/دروس-خصوصية.js" /* webpackChunkName: "component---src-pages-دروس-خصوصية-js" */),
  "component---src-templates-courses-js": () => import("./../../../src/templates/courses.js" /* webpackChunkName: "component---src-templates-courses-js" */),
  "component---src-templates-exercise-js": () => import("./../../../src/templates/exercise.js" /* webpackChunkName: "component---src-templates-exercise-js" */),
  "component---src-templates-lesson-js": () => import("./../../../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */),
  "component---src-templates-unites-js": () => import("./../../../src/templates/unites.js" /* webpackChunkName: "component---src-templates-unites-js" */)
}

