// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AttendanceStatus = {
  "PRESENT": "PRESENT",
  "TOCLASS": "TOCLASS",
  "ABSENT": "ABSENT"
};

const Subscriptions = {
  "FREE": "FREE",
  "SILVER": "SILVER",
  "GOLD": "GOLD"
};

const NoteType = {
  "OBSERVATION": "observation",
  "HOMEWORK": "homework",
  "EVENT": "event"
};

const { WebsiteBenahmedEducation, Keys, User, Students, Tutoring, Points, ContinuousMonitoring, Tests, Observations, Mark, Teacher, Lesson, SubTitle, Questions, Calsses, PointSchemas, PointSchema, Silo, PassWords, ExoSolution, ModelWebsiteBenahmedEducationConnection, ModelStudentsConnection, ModelUpdatedStudentConnection, FunctionBody } = initSchema(schema);

export {
  WebsiteBenahmedEducation,
  Keys,
  AttendanceStatus,
  Subscriptions,
  NoteType,
  User,
  Students,
  Tutoring,
  Points,
  ContinuousMonitoring,
  Tests,
  Observations,
  Mark,
  Teacher,
  Lesson,
  SubTitle,
  Questions,
  Calsses,
  PointSchemas,
  PointSchema,
  Silo,
  PassWords,
  ExoSolution,
  ModelWebsiteBenahmedEducationConnection,
  ModelStudentsConnection,
  ModelUpdatedStudentConnection,
  FunctionBody
};