import React from "react";
import { Link } from "gatsby";

const Banner = ({ featuredCourse, style = {} }) => {
  const { btn } = style;

  return (
    <section className={`banner banner-${featuredCourse.banner}`}>
      <div className="content">
        <header>
          <h1>{featuredCourse.title}</h1>
        </header>
        <p className="description">{featuredCourse.description}</p>
        <ul style={btn} className="actions">
          <li>
            <Link to={featuredCourse.slug} className="button big primary">
              {featuredCourse.btn ? featuredCourse.btn : "إقرأ المزيد"}
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Banner;
