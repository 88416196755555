import { DataStore, API } from "aws-amplify";
import { getDate } from "utility/helper/index";
import { WebsiteBenahmedEducation } from "../models";

import * as types from "./types";
import { listWebsiteBenahmedEducations } from "graphql/queries";
import { updateWebsiteBenahmedEducation } from "graphql/mutations";

/**
 *
 * @param {Partial<types.student> & Partial<types.teacher>} user
 * @returns
 */
export const userObject = user => {
  /**
   * get the subscription status of a user
   * @param {string} endDate
   * @returns boolean
   */
  const isSubscribed = endDate => {
    const _endDate = new Date(endDate);
    const currentDate = new Date();
    return _endDate >= currentDate;
  };
  console.log("in get user", user);
  if (user) {
    return {
      PK: user.PK,
      SK: user.SK,
      phone: user.phone,
      customerId: user?.GSI1_SK?.startsWith("index1#") ? user.GSI1_PK : null,
      subject: user.subject,
      phase: isNaN(parseInt(user.phase)) ? null : parseInt(user.phase),
      level: isNaN(parseInt(user.level)) ? null : parseInt(user.level),
      division: isNaN(parseInt(user.division)) ? null : parseInt(user.division),
      userType: user.userType,
      dataId: user.id,
      lastName: user.lastName,
      firstName: user.firstName,
      version: user._version,
      isSubscribed: isSubscribed(user.endSub),
      subscriptionEnd: getDate(new Date(user.endSub)),
      goldStars: user.goldStars,
      silverStars: user.silverStars,
      tutoring: user?.tutoring,
      _lastChangedAt: user._lastChangedAt,
    };
  }
  return null;
};
/**
 * a function to get user
 * @param {string} id
 * @param {function} setUserData
 * @returns
 */
export const getUser = async (id, setUserData) => {
  const subscription = DataStore.observeQuery(WebsiteBenahmedEducation, w => {
    return w.and(w => [w.PK.eq(id), w.SK.eq(`u#${id}`)]);
  }).subscribe(snapShot => {
    const { items, isSynced } = snapShot;
    const user = items[0];
    const userData = userObject(user);

    if (isSynced) {
      if (userData) {
        setUserData(state => ({
          ...state,
          isLoading: false,
          profile: {
            ...state.profile,
            phone: userData.phone,
            customerId: userData.customerId,
            userType: userData.userType,
            dataId: userData.dataId,
            phase: userData.phase,
            level: userData.level,
            division: userData.division,
            subject: userData.subject,
            firstName: userData.firstName,
            lastName: userData.lastName,
            version: userData.version,
            isSubscribed: userData.isSubscribed,
            subscriptionEnd: userData.subscriptionEnd,
            goldStars: userData.goldStars,
            silverStars: userData.silverStars,
            tutoring: userData.tutoring,
          },
        }));
      } else {
        setUserData(s => ({
          ...s,
          isLoading: false,
          profile: {
            ...s.profile,
            userType: "completeRegistration",
          },
        }));
      }
    }
  });
};

export const getAllUsers = async () => {
  const users = await API.graphql({
    query: listWebsiteBenahmedEducations,
    variables: { filter: { type: { eq: "user" } } },
  });
  return users;
};

export const setUser = async value => {
  return await DataStore.save(new WebsiteBenahmedEducation(value));
};

/**
 *
 * @param {string} id user id
 * @param {types.student & types.teacher} values
 * @returns
 */
export const updateUser = async (id, values) => {
  const [original] = await DataStore.query(WebsiteBenahmedEducation, w => {
    return w.and(w => [w.PK.eq(id), w.SK.eq(`u#${id}`)]);
  });
  const {
    phase,
    subject,
    level,
    division,
    userType,
    lastName,
    firstName,
    phone,
  } = values;
  return await DataStore.save(
    WebsiteBenahmedEducation.copyOf(original, updated => {
      if (phone) {
        updated.phone = phone.slice(1);
      }
      if (phase) {
        updated.phase = phase;
      }
      if (subject) {
        updated.subject = subject;
      }
      if (level) {
        updated.level = level;
      }
      if (division) {
        updated.division = division;
      }
      if (userType) {
        updated.userType = userType;
      }
      if (lastName) {
        updated.lastName = lastName;
      }
      if (firstName) {
        updated.firstName = firstName;
      }
    })
  );
};

export const updateStars = async (stars, PK, status, SK) => {
  try {
    if (stars > 0) {
      // update user gold stars
      const user = await API.graphql({
        query: updateWebsiteBenahmedEducation,
        variables: {
          input: { PK, SK: `u#${PK}`, goldStars: stars },
        },
      });
    }
    // update transaction status
    await API.graphql({
      query: updateWebsiteBenahmedEducation,
      variables: {
        input: { PK, SK, GSI1_SK: status },
      },
    });

    return true;
  } catch (error) {
    console.error("error in update stars", error);
  }
};
