import React from "react";

import Banner from "components/Banner";

const ArticlesAds = ({ to, content = {} }) => {
  return (
    <div className="ads print">
      <Banner
        featuredCourse={{
          title: "تريد حل المزيد من التمارين؟",
          description: "",
          btn: "إضغط هنا",
          slug: to,
          banner: "chemistry",
          ...content,
        }}
      />
    </div>
  );
};

export default ArticlesAds;
