import React, { createContext, useState, useEffect, useContext } from "react";

import { Auth, Hub, Amplify, Storage } from "aws-amplify";
import { getUser } from "data";
import { ls } from "utility/helper";

import awsConfig from "../aws-exports";
import { navigate } from "gatsby";

if (typeof window === "object") {
  awsConfig.oauth.redirectSignIn = `${window.location.origin}/App/thankyou/`;
  awsConfig.oauth.redirectSignOut = `${window.location.origin}/`;
}

Amplify.configure(awsConfig);
export const storage = Storage;

const userContext = createContext(undefined);

/**
 * @typedef {object} Profile
 * @prop {string} id
 * @prop {string} identityId
 * @prop {string=} email
 * @prop {string=} groupe
 * @prop {string=}  displayName
 * @prop {string=}  phone
 * @prop {string=} userType
 * @prop {string=} dataId
 * @prop {string=} phase
 * @prop {string=} level
 * @prop {string=} division
 * @prop {string=} subject
 * @prop {string=} firstName
 * @prop {string=} lastName
 * @prop {string=} version
 * @prop {string=} isSubscribed
 * @prop {string=} subscriptionEnd
 * @prop {string=} goldStars
 * @prop {string=} silverStars: string
 */
/**
 * @typedef {object} Details - user Details
 * @prop {boolean} isLoading: true,
 * @prop {boolean} isLoggedIn: false,
 * @prop {Profile|null} profile,
 */
function UserProvider({ children }) {
  /**
   * @type {[userDetail:Details, React.Dispatch<React.SetStateAction<Details>]}
   */
  const [userDetail, setUserDetail] = useState({
    isLoading: true,
    isLoggedIn: false,
    update: false,
    profile: null,
    userInfo: {},
    syncData: 0,
    isReady: false,
  });

  const login = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const credential = await Auth.currentUserCredentials();

      if (user) {
        const userId = user.username;
        console.log("in login", userId);
        ls("profile", {
          isLoggedIn: true,
          email: user.attributes.email,
          id: userId,
        });

        setUserDetail(state => ({
          ...state,
          isLoggedIn: true,
          isReady: true,
          profile: {
            ...state?.profile,
            identityId: credential.identityId,
            id: userId,
            displayName: user.attributes.email,
            email: user.attributes.email,
            groupe:
              user.signInUserSession.accessToken.payload["cognito:groups"],
          },
        }));
        return getUser(userId, setUserDetail);
      }
    } catch (e) {
      ls("profile", { isLoggedIn: false, email: "", id: "" });

      return setUserDetail(state => ({
        ...state,
        isLoggedIn: false,
        isLoading: false,
      }));
    }
  };
  const signout = async () => {
    ls("profile", { isLoggedIn: false, email: "", id: "" });
    setUserDetail({
      isLoading: false,
      isLoggedIn: false,
      profile: null,
    });
    navigate("/");
  };
  useEffect(() => {
    const listener = async data => {
      switch (data.payload.event) {
        case "signIn":
          console.info("user signed in");
          setUserDetail(s => ({ ...s, isLoading: true, isLoggedIn: true }));
          await login();
          break;
        case "signUp":
          console.info("user signed up");
          break;
        case "signOut":
          console.info("user signed out");
          await signout();
          break;
        case "signIn_failure":
          console.error("user sign in failed");
          break;
        case "tokenRefresh":
          console.info("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          console.error("token refresh failed");
          break;
        case "configured":
          console.info("the Auth module is configured");
      }
    };
    const dataListener = async data => {
      switch (data.payload.event) {
        case "ready":
          console.info("the DataStore is ready");
        // login();
      }
    };
    Hub.listen("datastore", dataListener);
    Hub.listen("auth", listener);
    login();
  }, []);

  return (
    <userContext.Provider value={{ userDetail, setUserDetail }}>
      {children}
    </userContext.Provider>
  );
}

/**
 * @typedef  {object} Context
 * @prop {Details} userDetail
 * @prop {React.Dispatch<React.SetStateAction<Details>>} setUserDetail
 */

/**
 *
 * @returns {Context| undefined}
 */
const useUser = () => {
  /**
   * @type {Context|undefined}
   */
  const context = useContext(userContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUser };
export default UserProvider;
