import { DataStore, API, SortDirection } from "aws-amplify";
import { Keys, WebsiteBenahmedEducation } from "../models";

export const setKey = async value => {
  const { PK, SK } = value;
  const [key] = await DataStore.query(Keys, k =>
    k.and(k => [k.PK.eq(PK), k.SK.eq(SK)])
  );
  if (key) {
    return false;
  }
  return await DataStore.save(new Keys(value));
};

export const addTransaction = async value => {
  const { PK, SK } = value;
  const [transaction] = await DataStore.query(WebsiteBenahmedEducation, t =>
    t.and(t => [t.PK.eq(PK), t.SK.eq(SK)])
  );
  if (transaction) {
    return false;
  }
  return await DataStore.save(new WebsiteBenahmedEducation(value));
};
/**
 *
 * @param {(string|string[])} type the
 * @param {*} setTransactions
 * @returns
 */
export const getAllTransactions = async (
  type,
  setTransactions = items => console.error("setTransactions is not defined")
) => {
  const subscription = await DataStore.observeQuery(
    WebsiteBenahmedEducation,
    t => {
      return typeof type === "string"
        ? t.and(t => [t.SK.beginsWith("tr#"), t.GSI1_SK.beginsWith(type)])
        : t.and(t => [
            t.SK.beginsWith("tr#"),
            t.or(t => type.map(tp => t.GSI1_SK.beginsWith(tp))),
          ]);
    },
    { sort: t => t.updatedAt(SortDirection.DESCENDING) }
  ).subscribe(snapShot => {
    const { items } = snapShot;
    setTransactions(items);
  });
  return subscription;
};
