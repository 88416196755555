import { WebsiteBenahmedEducation } from "../models";
import { DataStore, API } from "aws-amplify";
import {
  getWebsiteBenahmedEducation,
  updateStudentUserId,
} from "../graphql/updatedGq";

export const updateClassroom = async value => {
  const { PK, SK, pointSchema, observations, currentTrimester } = value;
  const [classroom] = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  if (classroom) {
    const newClassroom = await DataStore.save(
      WebsiteBenahmedEducation.copyOf(classroom, updated => {
        if (pointSchema) {
          const [trimester, schema] = pointSchema;
          updated.pointSchema = {
            ...updated.pointSchema,
            [`trimester${trimester}`]: schema,
          };
        }
        if (observations) {
          updated.observations = observations;
        }
        if (currentTrimester) {
          updated.currentTrimester = currentTrimester;
        }
      })
    );
    return classroomRes(newClassroom);
  }
};

export const setClassroom = async (
  value = {
    PK: "",
    SK: "",
    title: "",
    observations: [],
    pointSchema: {},
    type: "classroom",
  },
  override = false
) => {
  const { PK, SK } = value;
  const [classroom] = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  if (classroom) {
    if (override) {
      return updateClassroom(classroom);
    } else {
      return false;
    }
  }
  return await DataStore.save(new WebsiteBenahmedEducation(value));
};

const classroomRes = classroom => {
  const {
    PK,
    SK,
    pointSchema,
    observations,
    title,
    currentTrimester,
  } = classroom;
  const [, , classroomId] = SK.split("#");
  return {
    PK,
    SK,
    pointSchema: {
      1: pointSchema.trimester1,
      2: pointSchema.trimester2,
      3: pointSchema.trimester3,
    },
    observations,
    title,
    classroomId,
    currentTrimester,
  };
};

export const getAllClassrooms = async (PK, SK) => {
  const classrooms = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.beginsWith(SK)])
  );
  if (classrooms.length > 0) {
    return classrooms.map(classroom => classroomRes(classroom));
  }
  return classrooms;
};

export const getStudentsByClassroom = async ({
  teacherId,
  classroomId,
  schoolYear,
}) => {
  const values = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [
      w.GSI1_PK.eq(teacherId),
      w.GSI1_SK.beginsWith(`c#${classroomId}#${schoolYear}`),
    ])
  );
  return getStudentsRes(values);
};

export const getStudentByUserId = async ({
  userId,
  schoolYear,
  trimester = 1,
}) => {
  const values = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [
      w.GSI2_PK.eq(userId),
      w.GSI2_SK.beginsWith(`${schoolYear}${trimester}`),
    ])
  );
  if (values.length > 0) {
    const student = getStudentsRes(values).reduce((acc, value) => {
      return { ...acc, [value.subject]: value };
    }, {});
    return student;
  } else {
    return false;
  }
};

export const getStudent = async (teacherId, studentNumber) => {
  const student = await API.graphql({
    query: getWebsiteBenahmedEducation,
    variables: { PK: teacherId, SK: `s#${studentNumber}` },
  });
  if (student) {
    return student.data.getWebsiteBenahmedEducation;
  } else {
    return false;
  }
};

export const getTutees = async (day = "السبت", time = "9:30 - 11:00") => {
  const tutees = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.userType.eq("student")])
  );
  return tutees.filter(
    t => t?.tutoring?.day === day && t?.tutoring?.time === time
  );
};
export const setStudent = async input => {
  const { PK, SK } = input;
  const original = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  if (original[0]) {
    return false;
  } else {
    return await DataStore.save(new WebsiteBenahmedEducation(input));
  }
};

export const updateStudent = async value => {
  const {
    lastName,
    firstName,
    birthday,
    summonCounter,
    summonDate,
    summoned,
    confirmed,
    day,
    time,
    sex,
    PK,
    SK,
  } = value;
  const [original] = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  return await DataStore.save(
    WebsiteBenahmedEducation.copyOf(original, updated => {
      if (lastName) {
        updated.lastName = lastName;
      }
      if (sex) {
        updated.sex = sex;
      }
      if (birthday) {
        updated.birthday = birthday;
      }
      if (firstName) {
        updated.firstName = firstName;
      }
      if (typeof summonCounter !== "undefined") {
        updated.summonCounter = summonCounter;
      }
      if (typeof summonDate !== "undefined") {
        updated.summonDate = summonDate;
      }
      if (typeof summoned !== "undefined") {
        updated.summoned = summoned;
      }
      if (typeof confirmed !== "undefined") {
        updated.tutoring.confirmed = confirmed;
      }
      if (typeof day !== "undefined") {
        updated.tutoring.day = day;
      }
      if (typeof time !== "undefined") {
        updated.tutoring.time = time;
      }
    })
  );
};

export const updateStudentUserInfo = async (password, userId) => {
  const response = await API.graphql({
    query: updateStudentUserId,
    variables: {
      password,
      userId: `u#${userId}`,
    },
  });
  const { code, message } = response.data.updateStudentUserId.body;
  if (code === 200) {
    await DataStore.stop();
    await DataStore.start();
  }
  return { code, message };
};

export const updateStudentClassroom = async (
  PK,
  SK,
  classroomId,
  schoolYear
) => {
  const values = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.beginsWith(SK)])
  );
  return await Promise.all(
    values.map(async value => {
      return await DataStore.save(
        WebsiteBenahmedEducation.copyOf(value, updated => {
          const trimester = updated.GSI1_SK.slice(-1);
          updated.GSI1_SK = `c#${classroomId}#${schoolYear}${trimester}`;
        })
      );
    })
  );
};

export const deleteStudent = async SK => {
  return await DataStore.delete(WebsiteBenahmedEducation, w =>
    w.SK.beginsWith(SK)
  );
};

export const setTeacher = async input => {
  const { PK, SK, subject } = input;
  const original = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  if (original[0]) {
    return await DataStore.save(
      WebsiteBenahmedEducation.copyOf(original[0], updated => {
        if (subject) {
          updated.subject = subject;
        }
      })
    );
  } else {
    return await DataStore.save(new WebsiteBenahmedEducation(input));
  }
};

export const getTeacher = async teacherId => {
  const teacher = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(teacherId), w.SK.eq(`t#${teacherId}`)])
  );
  if (teacher[0]) {
    return teacher[0];
  } else {
    return false;
  }
};

export const setPoints = async input => {
  const { PK, SK } = input;
  const original = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  if (original[0]) {
    return original[0];
  } else {
    return await DataStore.save(new WebsiteBenahmedEducation(input));
  }
};

export const setObservations = async input => {
  const { PK, SK, cause, mark } = input;
  const [original] = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  if (original) {
    return await DataStore.save(
      WebsiteBenahmedEducation.copyOf(original, updated => {
        if (cause) {
          updated.cause = cause;
        }
        if (mark) {
          updated.mark = mark;
        }
      })
    );
  } else {
    return await DataStore.save(new WebsiteBenahmedEducation(input));
  }
};

export const getObservations = async (PK, SK = "ob#") => {
  const observations = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.beginsWith(SK)])
  );
  return observations.map(ob => ({
    PK: ob.PK,
    SK: ob.SK,
    cause: ob.cause,
    mark: ob.mark,
    date: ob.observationDate,
    type: ob.type,
  }));
};

export const deleteObservation = async (PK, SK) => {
  const [original] = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  if (original) {
    return await DataStore.delete(original);
  }
};

export const updatePoints = async value => {
  const { continuousMonitoring, labWork, tests, exam, PK, SK } = value;
  const original = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  return await DataStore.save(
    WebsiteBenahmedEducation.copyOf(original[0], updated => {
      if (continuousMonitoring) {
        updated.continuousMonitoring = {
          ...updated.continuousMonitoring,
          ...continuousMonitoring,
        };
      }
      if (labWork) {
        updated.labWork = labWork;
      }
      if (tests) {
        updated.tests = { ...updated.tests, ...tests };
      }
      if (exam) {
        updated.exam = exam;
      }
    })
  );
};

export const setPassword = async input => {
  const { studentId, teacherId } = input;
  const original = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.teacherId.eq(teacherId), w.studentId.eq(studentId)])
  );
  if (original.length >= 2) {
    return false;
  } else {
    return await DataStore.save(new WebsiteBenahmedEducation(input));
  }
};

export const getStudentsRes = values => {
  if (values) {
    const students = [],
      points = [],
      passwords = [],
      observations = [];
    values.forEach(value => {
      if (value.type === "student") {
        const {
          id,
          PK,
          SK,
          GSI1_PK,
          GSI1_SK,
          GSI2_PK,
          GSI2_SK,
          GSI3_PK,
          GSI3_SK,
          lastName,
          firstName,
          birthday,
          sex,
          summoned,
          summonDate,
          summonCounter,
          attendancy,
          subject,
          type,
          _version,
        } = value;
        students.push({
          id,
          PK,
          SK,
          GSI1_PK,
          GSI1_SK,
          GSI2_PK,
          GSI2_SK,
          GSI3_PK,
          GSI3_SK,
          firstName,
          lastName,
          birthday,
          sex,
          points: [],
          passwords: [],
          observations: [],
          summoned,
          summonDate,
          summonCounter,
          attendancy,
          subject,
          type,
          _version,
        });
      }
      if (value.type === "point") {
        const {
          id,
          PK,
          SK,
          continuousMonitoring,
          labWork,
          tests,
          exam,
          _version,
          GSI2_SK,
        } = value;
        points.push({
          id,
          PK,
          SK,
          continuousMonitoring,
          labWork,
          tests,
          exam,
          _version,
          GSI2_SK,
        });
      }
      if (value.type === "password") {
        const { PK, teacherId, studentId, id } = value;
        passwords.push({
          PK,
          id,
          teacherId,
          studentId,
        });
      }
      if (value.type === "observation") {
        const {
          id,
          PK,
          SK,
          cause,
          mark: { amount },
          observationDate,
        } = value;
        observations.push({
          id,
          PK,
          SK,
          cause,
          amount,
          observationDate,
        });
      }
    });

    return students.map(student => {
      return {
        ...student,
        points: points
          .filter(
            point => point["SK"] === `${student["SK"]}#${point["GSI2_SK"]}`
          )
          .reduce((acc, value) => {
            return { ...acc, [value["SK"].slice(-1)]: value };
          }, {}),
        observations: observations.filter(
          observation => observation.SK.split("#ob")[0] === student.SK
        ),
      };
    });
  } else {
    return [];
  }
};
