import { DataStore } from "aws-amplify";
import { WebsiteBenahmedEducation } from "models";

export const addError = async values => {
  const error = await DataStore.save(
    new WebsiteBenahmedEducation({
      ...values,
      type: "errors",
      PK: "google_115848714034702381069",
      SK: `E#${Date.now}`,
    })
  );
  return error;
};
