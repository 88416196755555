import { getQuestionId } from "../utility/helper";
import { DataStore } from "aws-amplify";
import { WebsiteBenahmedEducation } from "../models";

export const updateRevision = async (id, value) => {
  // todo impliment update revision
};

export const setRevisionQuestion = async value => {
  const { PK, SK, difficulty, question, result, suggestions } = value;
  const [questionValue] = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  if (questionValue) {
    return await DataStore.save(
      WebsiteBenahmedEducation.copyOf(questionValue, updated => {
        if (difficulty) {
          updated.difficulty = difficulty;
        }
        if (question) {
          updated.question = question;
        }
        if (result) {
          updated.result = result;
        }
        if (suggestions) {
          updated.suggestions = suggestions;
        }
      })
    );
  }
  return await DataStore.save(new WebsiteBenahmedEducation(value));
};

export const getRevision = async id => {
  // todo impliment get revision
};

export const deleteRevision = async id => {
  const question = await DataStore.query(WebsiteBenahmedEducation, id);
  return await DataStore.delete(question);
};

export const getRevisionQuestions = async (PK, values) => {
  return await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [
      w.PK.eq(PK),
      w.SK.beginsWith(getQuestionId(values ? values : {})),
      w.type.eq("question"),
    ])
  );
};

export const getQuestions = async (
  PK,
  values,
  userType = "teacher",
  myQuestionsOnly = true
) => {
  const { order } = values;
  if (!order) {
    return [];
  }
  if (!myQuestionsOnly || userType !== "teacher") {
    const questions = await DataStore.query(WebsiteBenahmedEducation, w =>
      w.and(w => [
        w.GSI1_SK.le(`${getQuestionId(values)}9`),
        w.type.eq("question"),
      ])
    );
    return questions;
  }
  const questions = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [
      w.PK.eq(PK),
      w.SK.gt(getQuestionId({ ...values, order: values.minOrder })),
      w.SK.le(`${getQuestionId(values)}9`),
      ,
      w.type.eq("question"),
    ])
  );
  return questions;
};
