export const LESSONS = "lessons";
export const EXERCISES = "exercises";
export const USERS = "users";
export const REVISIONS = "revisions";
export const SCORES = "scores";
export const GAMEINFO = "gameInfo";
export const CLASSROOMS = "classrooms";
export const STUDENTS = "students";

export const adminId = "pj8VZxD124b6s0oZPJVDqb3UIyx2";

export const translation = {
  teacher: "أستاذ",
  student: "تلميذ",
  parent: "ولي أمر",
  user: "مستخدم",
  مستخدم: "user",
  أستاذ: "teacher",
  تلميذ: "student",
  "ولي أمر": "parent",
};

export const initStudentsInfo = {
  summoned: false,
  summonDate: "لا يوجد إستدعاء",
  summonCounter: 0,
  attendancy: "PRESENT",
};
export const initTrimester = {
  labWork: 0,
  tests: {
    test1: 0,
    test2: 0,
  },
  exam: 0,
  conditionExam: 10,
  conditionVersion: 1,
  conditionNote: "",
};

export const lessonInfo = {
  "أولى  ثانوي": {
    "جذع مشترك علوم و تكنولوجيا": {
      الميكانيك: [
        "القوى و الحركات المستقيمة",
        "القوى و الحركات المنحنية",
        "الحركة القوة و المرجع",
        "دفع و كبح متحرك",
        "التماسك في المادة و في الفضاء",
      ],
      "المادة و تحولاتها": [
        "بنية و هندسة أفراد بعض الأنواع الكيميائية",
        "من المجهري إلى العياني",
        "المقاربة الكيمة لتحول كيميائي",
      ],
      "الظواهر الضوئية": [
        "انكسار الضوء",
        "الضوء الأبيض والضوء وحيد اللون",
        "أطياف الإصدار و الإمتصاص",
      ],
    },
    "جذع مشترك آداب": {
      "الإنسان و الطبيعة": ["الماء في الطبيعة", "الهواء من حولنا"],
      "الإنسان و الإتصال": [
        "الضوء للرؤية",
        "الضوء للإتص",
        "الضوء و أبعاد الكون",
      ],
      "الإنسان و الطاقة": ["ما هي الطاقة؟", "السلاسل الطاقوية"],
    },
  },
  "ثانية ثانوي": {
    "علوم تجريبية": {
      "الميكانيك و الطاقة": [
        "مقاربة كيفية لطاقة جملة و انحفاظها",
        "العمل والطاقة الحركية",
        "الطاقة الكامنة",
        "الطاقة الداخلية",
      ],
      "المادة و تحولاتها": [
        "تعين كمية المادة عن طريق قياس الناقلية",
        "تعين كمية المادة بواسطة المعايرة",
        "مدخل إلى الكيمياء العضوية",
      ],
      "الظواهر الكهربائية": [
        "مفهوم الحقل المغناطيسي",
        "مقاربة الأفعال الكهرومغناطيسية",
      ],
      "الظواهر الضوئية": [
        "العدسات عناصر لعدة أجهزة بصرية",
        "الصورة المعطاة من طرف عدسة",
        "نمذجة عدسة مقربة",
      ],
    },
    رياضيات: {},
    "تقني رياضي": {},
    "آداب و فلسفة": {
      "الإنسان و المادة": [
        "الكيمياء و تحولات المادة",
        "الكيمياء في الحياة اليومية",
      ],
      "الانسان و الاتصال": ["الضوء للرؤية", "الصوت"],
      "الإنسان و الطاقة": [
        "السلاسل الطاقوية",
        "الطاقة في الحياة اليومية",
        "كيف نضمن حاجاتنا للطاقة",
      ],
    },
  },
  "ثالثة ثانوي": {
    "علوم تجريبية": {},
    رياضيات: {},
    "تقني رياضي": {},
  },
};

export const levelsInfo = {
  1: [
    { name: ["سنة أولى إبتدائي", 1] },
    { name: ["سنة ثانية إبتدائي", 2] },
    { name: ["سنة ثالثة إبتدائي", 3] },
    { name: ["سنة رابعة إبتدائي", 4] },
    { name: ["سنة خامسة إبتدائي", 5] },
  ],

  2: [
    { name: ["سنة الأولى متوسط", 1] },
    { name: ["سنة الثانية متوسط", 2] },
    { name: ["سنة الثالثة متوسط", 3] },
    { name: ["سنة الرابعة متوسط", 4] },
  ],
  3: [
    {
      name: ["سنة أولى ثانوي", 1],
      divisions: [
        ["جذع مشترك علوم و تكنولوجيا", 1],
        ["جذع مشترك آداب", 2],
      ],
    },
    {
      name: ["سنة ثانية ثانوي", 2],
      divisions: [
        ["علوم تجريبية", 1],
        ["رياضيات", 2],
        ["تقني رياضي", 3],
        ["آداب و فلسفة", 4],
        ["تسيير و إقتصاد", 5],
        ["لغات", 6],
      ],
    },
    {
      name: ["سنة ثالثة ثانوي", 3],
      divisions: [
        ["علوم تجريبية", 1],
        ["رياضيات", 2],
        ["تقني رياضي", 3],
        ["آداب و فلسفة", 4],
        ["تسيير و إقتصاد", 5],
        ["لغات", 6],
      ],
    },
  ],
};

const commonSubjects = [
  "رياضيات",
  "تاريخ و جغرافيا",
  "تربية بدنية",
  "تربية إسلامية",
  "لغة إنجليزية",
  "لغة فرنسية",
  "لغة عربية",
];
const scienceSubjects = ["علوم فيزيائية", "علوم طبيعية"];
const technicalSubject = [
  "هندسة ميكانيكية",
  "هندسة كهربائية",
  "هندسة مدنية",
  "هندسة الطرائق",
];
const lagnuageSubject = ["لغة ألمانية", "لغة إسبانية"];
const philosophySubject = ["فلسفة"];
export const subjects = {
  1: ["لغة عربية", "لغة فرنسية"],
  2: [...commonSubjects, ...scienceSubjects],
  3: [
    ...commonSubjects,
    ...scienceSubjects,
    ...technicalSubject,
    ...lagnuageSubject,
    ...philosophySubject,
  ],
};

export const daysNamesAr = {
  0: "الأحد",
  1: "الإثنين",
  2: "الثلاثاء",
  3: "الأربعاء",
  4: "الخميس",
  5: "الجمعة",
  6: "السبت",
};

export const monthsNamesAr = {
  0: "جانفي",
  1: "فيفري",
  2: "مارس",
  3: "أفريل",
  4: "ماي",
  5: "جوان",
  6: "جويلية",
  7: "أوت",
  8: "سبتمبر",
  9: "أكتوبر",
  10: "نوفمبر",
  11: "ديسمبر",
};

export const unites = {
  "سنة أولى ثانوي جذع مشترك علوم و تكنولوجيا": [
    "بنية و هندسة أفراد بعض الأنواع الكيميائية",
    "القوى و الحركات المستقيمة",
    "القوى و الحركات المنحنية",
    "الحركة و القوة و المرجع",
    "دفع و كبح متحرك",
    "من المجهري إلى العياني",
    "التماسك في المادة و في الفضاء",
    "انكسار الضوء",
    "الضوء الأبيض و الضوء وحيد اللون",
    "المقاربة الكمية لتحول كيميائي",
    "أطياف الإصدار و أطياف الإمتصاص",
  ],
  "سنة أولى ثانوي جذع مشترك آداب": [
    "الماء في الطبيعة",
    "الهواء من حولنا",
    "الضوء للرؤية",
    "الضوء للاتصال",
    "الضوء وأبعاد الكون",
    "ما هي الطاقة ؟",
    "السلاسل الطاقوية",
  ],
  "سنة ثانية ثانوي علوم تجريبية": [
    "مقاربة كيفية لطاقة جملة و انحفاظها",
    "العمل و الطاقة الحركية",
    "الطاقة الكامنة",
    "تعيين كمية المادة عن طريق قياس الناقلية",
    "الطاقة الداخلية",
    "تعيين كمية المادة بواسطة المعايرة",
    "مفهوم الحقل المغناطيسي",
    "مقاربات األفعال المتبادلة الكهرومغناطيسية",
    "مدخل إلى الكيمياء العضوية",
    "العدسات عناصر لعدة أجهزة بصرية",
    "الصورة المعطاة من طرف عدسة",
    "نمذجة عدسة مقربة : العدسة الرقيقة",
  ],
  "سنة ثانية ثانوي آداب و فلسفة": [
    "الكيمياء و تحولات المادة",
    "الكيمياء في الحياة اليومية",
    "الضوء للرؤية",
    "الصوت",
    "الطاقة في الحياة اليومية",
    "كيف تضمن حاجياتنا للطاقة",
    "كيف يتم نقل الطاقة",
  ],
  "سنة ثالثة ثانوي علوم تجريبية": [
    "المتابعة الزمنية لتحول كيميائي في وسط مائي",
    "تطور جملة ميكانيكية",
    "دراسة الظواهر الكهربائية",
    "تطور جملة كيميائية نحو حالة التوازن",
    "التحولات النووية",
    "مراقبة تطور جملة كيميائية",
    "التطورات المهتزة",
    "مفهوم الموجة",
  ],
};
