import React from "react";
import { Link } from "gatsby";

const GoToTuto = ({ level, division }) => {
  return (
    <Link
      to={`/App/profile?level=${level}&division=${division}#tuto`}
      className="button big primary"
    >
      سجل الآن!
    </Link>
  );
};

export default GoToTuto;
