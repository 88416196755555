import React, { createContext, useState } from "react";

const questionContext = createContext(undefined);
const questionDispatchContext = createContext(undefined);

function QuestionProvider({ children }) {
  const [questionDetails, setQuestionDetails] = useState({
    title: "",
    allTitles: [],
    answer: "",
  });
  return (
    <questionContext.Provider value={questionDetails}>
      <questionDispatchContext.Provider value={setQuestionDetails}>
        {children}
      </questionDispatchContext.Provider>
    </questionContext.Provider>
  );
}

export { QuestionProvider, questionContext, questionDispatchContext };
