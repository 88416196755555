import React from "react";

import "./myVideo.scss";

const MyVideo = ({ source, children = null }) => {
  return (
    <div className="video">
      <div className="video--content">{children}</div>
      <div className="video--wrapper">
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${source}`}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default MyVideo;
